<template>
  <page :styleWrap="{'background': '#fff'}">
    <div>
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div>
        <p style="color: #2D2D2D; font-size: 13px; padding: 15px">为了您的账户安全，请验证手机号</p>
        <div>
          <van-field class="f_flex f_a_c fieldCell" label-width="1" placeholder="请输入手机号" v-model="phone" type='tel'>
            <div slot='label' style="font-size: 15px;  color: #BABABA"></div>
          </van-field>

          <van-field class="f_flex f_a_c fieldCell" label-width="1" placeholder="短信验证码" v-model="code"  type="tel">
            <div slot='label' style="font-size: 15px;  color: #BABABA"></div>
            <template #button>
              <count-down style="padding-right: 16px" class="theme_c" :state='codeState' @change='sendCode'></count-down>
            </template>
          </van-field>

          <van-field class="f_flex f_a_c fieldCell" label-width="1" v-model="pwd" placeholder="请输入新密码" :type="inputType">
            <div slot='label' style="font-size: 15px;  color: #BABABA"></div>
            <template #button>
              <toggle-pwd style="padding-right: 16px" :inputType="inputType" @change='changeInputType'></toggle-pwd>
            </template>
          </van-field>

          <van-field class="f_flex f_a_c fieldCell" label-width="1" placeholder="再次输入密码" v-model="pwd_new" :type="inputTypeNew">
            <div slot='label' style="font-size: 15px;  color: #BABABA"></div>
            <template #button>
              <toggle-pwd style="padding-right: 16px" :inputType="inputTypeNew" @change='changeInputTypeNew'></toggle-pwd>
            </template>
          </van-field>
          <p style="font-size: 12px; color: #9B9B9B; margin-top: 8px; margin-left: 15px">请设置6-18位密码</p>
        </div>
      </div>

      <button @click='resetPwdFn' class="btn_radius margin_auto register_btn " :class="[pwd==pwd_new && pwd != '' ? 'theme_bg' : 'theme_dis_bg']">确认修改</button>
    </div>
  </page>
</template>

<script>
import { Field } from 'vant';
import smgApi from '@/api/other'
import togglePwd from './togglePwd.vue'
import countDown from '@/components/countDown/index.vue'
import loginApi from '@/api/user'

export default {
  data: ()=> ({
    phone: '',
    code: '',
    pwd: '',
    inputType: 'password',
    pwd_new: '',
    inputTypeNew: 'password',
    codeState: false,
  }),
  components: {
    'van-field': Field,
    'toggle-pwd': togglePwd,
    countDown
  },
  methods: {
    countDownFn() {
      this.codeState = true
      setTimeout(()=> {
        this.codeState = false
      }, 60000)
    },
    sendCode() {
      if (this.phone.match(/^1\d{10}$/g)) {
        this.$toast.loading({
          message: '正在发送...',
          forbidClick: true,
        });
        smgApi.send({
          phone: this.phone,
          bizType: 2
        }).then(res=> {
          this.$toast.success(res.data.msg)
          this.countDownFn()
        }).finally(()=> {
          setTimeout(()=> {
            this.$toast.clear()
          }, 1500)
        })
      } else {
        this.$toast('手机号不正确')
      }
    },
    changeInputType(e) {
      this.inputType = e
    },
    changeInputTypeNew(e) {
      this.inputTypeNew = e
    },
    verifyFn() {
      const obj = {
        phone: {
          test: /^1\d{10}$/,
          msg: '手机号不正确'
        },
        code: {
          test: /\d{4,8}/,
          msg: '验证码不正确'
        },
        pwd: {
          test: /^\S{6,18}$/,
          msg: '密码设置6-18位'
        },
        pwd_new: {
          test: /^\S{6,18}$/,
          msg: '密码设置6-18位'
        }
      }
      let b = true
      for(let [k,v] of Object.entries(obj)) {
        if (!(this[k] && v.test.test(this[k]))) {
          this.$toast(v.msg)
          b = false
          return b
        }
      }
      return b
    },
    resetPwdFn() {
      const b = this.verifyFn()
      if (b && this.pwd && this.pwd === this.pwd_new) {
        loginApi.forgetPasswordReset({
          phone: this.phone,
          verifyCode: this.code,
          newPassword: this.pwd,
          confirmPassword: this.pwd_new 
        }).then(res => {
          if (res.data) {
            this.$toast(res.data.msg)
            this.code = ''
            this.pwd = ''
            this.pwd_new =''
            this.phone = ''
            setTimeout(()=> {
              this.$router.back()
            })
          }
        })
      }
    }
  },
}
</script>

<style lang="less" scoped>
.phoneTip {
  font-size: 13px;
  color: #666;
  margin-top: 60px;
  margin-bottom: 45px;
}
.fieldCell {
  height: 58px;
  font-size: 15px;
  padding: 0;
}
.fieldLabel {
  color: #BABABA;
  font-size: 15px;
}
.register_btn {
  width: 70%;
  margin-top: 94px;
}
</style>